<template>
  <b-modal
    id="popup-contract-maintenance-types"
    ref="popup-contract-maintenance-types"
    centered="centered"
    :title="(interventionType.id ? 'Modifier' : 'Ajouter') + ` un type de contrat de maintenance`"
    @ok="create"
  >
    <div v-if="isCreatingInterventionType">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formInterventionType">
      <b-row>
        <b-col cols="12" class="d-flex flex-column mb-1">
          <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
            <validation-provider #default="{ errors }" name="Libellé" rules="required">
              <b-form-input
                id="label"
                v-model="interventionType.label"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
                v-on:keyup.enter="create"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group class="mt-1" label="Produits compris ? *" label-for="Produits compris ?" style="flex: 1">
            <validation-provider #default="{ errors }" name="Produits compris ?" rules="required">
              <div>
                <b-form-radio v-model="interventionType.productsInclude" :value="true" name="productsInclude" inline> Produits compris </b-form-radio>
                <b-form-radio v-model="interventionType.productsInclude" :value="false" name="productsInclude" inline> Produits non compris </b-form-radio>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button
          class="float-left"
          v-if="interventionType.id"
          variant="outline-danger"
          @click="deleteInterventionTypeLocal(interventionType.id, interventionType.label)"
          ><feather-icon icon="Trash2Icon"
        /></b-button>
        <b-button class="float-right" variant="primary" @click="ok()">{{ interventionType.id ? "Modifier" : "Ajouter" }}</b-button>
        <b-button class="float-right mr-1" variant="outline-primary" @click="cancel()">Annuler</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure, localize } from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      interventionType: {
        label: "",
        productsInclude: false,
        workspaceId: this.$store.getters.workspaceSelected.id,
      },
    };
  },
  methods: {
    popupCreateContractMaintenanceTypesEvent(id) {
      if (id > 0) {
        this.fetchContractMaintenanceType(id).then((res) => {
          this.interventionType = res;
        });
      } else this.initializeForm();
    },
    initializeForm() {
      this.interventionType = {
        label: "",
        productsInclude: false,
        workspaceId: this.$store.getters.workspaceSelected.id,
      };
    },
    deleteInterventionTypeLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est irréversible.", {
          title: `Êtes-vous sûr de vouloir supprimer le type de contract de maintenance ${label} ?`,
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.archiveContractMaintenanceType(id);
            this.$nextTick(() => {
              this.$bvModal.hide("popup-contract-maintenance-types");
            });
          }
        });
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formInterventionType.validate().then((success1) => {
        if (success1) {
          if (this.interventionType.id) this.updateContractMaintenanceType(this.interventionType);
          else this.createContractMaintenanceType(this.interventionType);
          this.$nextTick(() => {
            this.$refs["popup-contract-maintenance-types"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions(["createContractMaintenanceType", "updateContractMaintenanceType", "fetchContractMaintenanceType", "archiveContractMaintenanceType"]),
  },
  computed: {
    ...mapGetters(["isCreatingInterventionType"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
};
</script>
